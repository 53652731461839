import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['path', { d: 'M16 3h5v5', key: '1806ms' }],
  ['path', { d: 'M17 21h2a2 2 0 0 0 2-2', key: '130fy9' }],
  ['path', { d: 'M21 12v3', key: '1wzk3p' }],
  ['path', { d: 'm21 3-5 5', key: '1g5oa7' }],
  ['path', { d: 'M3 7V5a2 2 0 0 1 2-2', key: 'kk3yz1' }],
  ['path', { d: 'm5 21 4.144-4.144a1.21 1.21 0 0 1 1.712 0L13 19', key: 'fyekpt' }],
  ['path', { d: 'M9 3h3', key: 'd52fa' }],
  ['rect', { x: '3', y: '11', width: '10', height: '10', rx: '1', key: '1wpmix' }],
];

/**
 * @component @name ImageUpscale
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTYgM2g1djUiIC8+CiAgPHBhdGggZD0iTTE3IDIxaDJhMiAyIDAgMCAwIDItMiIgLz4KICA8cGF0aCBkPSJNMjEgMTJ2MyIgLz4KICA8cGF0aCBkPSJtMjEgMy01IDUiIC8+CiAgPHBhdGggZD0iTTMgN1Y1YTIgMiAwIDAgMSAyLTIiIC8+CiAgPHBhdGggZD0ibTUgMjEgNC4xNDQtNC4xNDRhMS4yMSAxLjIxIDAgMCAxIDEuNzEyIDBMMTMgMTkiIC8+CiAgPHBhdGggZD0iTTkgM2gzIiAvPgogIDxyZWN0IHg9IjMiIHk9IjExIiB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHJ4PSIxIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/image-upscale
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const ImageUpscale = createLucideIcon('ImageUpscale', __iconNode);

export default ImageUpscale;
