import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['path', { d: 'M18 12h2', key: 'quuxs7' }],
  ['path', { d: 'M18 16h2', key: 'zsn3lv' }],
  ['path', { d: 'M18 20h2', key: '9x5y9y' }],
  ['path', { d: 'M18 4h2', key: '1luxfb' }],
  ['path', { d: 'M18 8h2', key: 'nxqzg' }],
  ['path', { d: 'M4 12h2', key: '1ltxp0' }],
  ['path', { d: 'M4 16h2', key: '8a5zha' }],
  ['path', { d: 'M4 20h2', key: '27dk57' }],
  ['path', { d: 'M4 4h2', key: '10groj' }],
  ['path', { d: 'M4 8h2', key: '18vq6w' }],
  [
    'path',
    {
      d: 'M8 2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-1.5c-.276 0-.494.227-.562.495a2 2 0 0 1-3.876 0C9.994 2.227 9.776 2 9.5 2z',
      key: '1681fp',
    },
  ],
];

/**
 * @component @name Microchip
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTggMTJoMiIgLz4KICA8cGF0aCBkPSJNMTggMTZoMiIgLz4KICA8cGF0aCBkPSJNMTggMjBoMiIgLz4KICA8cGF0aCBkPSJNMTggNGgyIiAvPgogIDxwYXRoIGQ9Ik0xOCA4aDIiIC8+CiAgPHBhdGggZD0iTTQgMTJoMiIgLz4KICA8cGF0aCBkPSJNNCAxNmgyIiAvPgogIDxwYXRoIGQ9Ik00IDIwaDIiIC8+CiAgPHBhdGggZD0iTTQgNGgyIiAvPgogIDxwYXRoIGQ9Ik00IDhoMiIgLz4KICA8cGF0aCBkPSJNOCAyYTIgMiAwIDAgMC0yIDJ2MTZhMiAyIDAgMCAwIDIgMmg4YTIgMiAwIDAgMCAyLTJWNGEyIDIgMCAwIDAtMi0yaC0xLjVjLS4yNzYgMC0uNDk0LjIyNy0uNTYyLjQ5NWEyIDIgMCAwIDEtMy44NzYgMEM5Ljk5NCAyLjIyNyA5Ljc3NiAyIDkuNSAyeiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/microchip
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Microchip = createLucideIcon('Microchip', __iconNode);

export default Microchip;
