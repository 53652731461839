import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['path', { d: 'M14 4.1 12 6', key: 'ita8i4' }],
  ['path', { d: 'm5.1 8-2.9-.8', key: '1go3kf' }],
  ['path', { d: 'm6 12-1.9 2', key: 'mnht97' }],
  ['path', { d: 'M7.2 2.2 8 5.1', key: '1cfko1' }],
  [
    'path',
    {
      d: 'M9.037 9.69a.498.498 0 0 1 .653-.653l11 4.5a.5.5 0 0 1-.074.949l-4.349 1.041a1 1 0 0 0-.74.739l-1.04 4.35a.5.5 0 0 1-.95.074z',
      key: 's0h3yz',
    },
  ],
];

/**
 * @component @name MousePointerClick
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTQgNC4xIDEyIDYiIC8+CiAgPHBhdGggZD0ibTUuMSA4LTIuOS0uOCIgLz4KICA8cGF0aCBkPSJtNiAxMi0xLjkgMiIgLz4KICA8cGF0aCBkPSJNNy4yIDIuMiA4IDUuMSIgLz4KICA8cGF0aCBkPSJNOS4wMzcgOS42OWEuNDk4LjQ5OCAwIDAgMSAuNjUzLS42NTNsMTEgNC41YS41LjUgMCAwIDEtLjA3NC45NDlsLTQuMzQ5IDEuMDQxYTEgMSAwIDAgMC0uNzQuNzM5bC0xLjA0IDQuMzVhLjUuNSAwIDAgMS0uOTUuMDc0eiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/mouse-pointer-click
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const MousePointerClick = createLucideIcon('MousePointerClick', __iconNode);

export default MousePointerClick;
