import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['path', { d: 'M10 18h4', key: '1ulq68' }],
  ['path', { d: 'M11 6H3', key: '1u26ik' }],
  ['path', { d: 'M15 6h6', key: '1jlkvy' }],
  ['path', { d: 'M18 9V3', key: 'xwwp7m' }],
  ['path', { d: 'M7 12h8', key: '7a1bxv' }],
];

/**
 * @component @name ListFilterPlus
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgMThoNCIgLz4KICA8cGF0aCBkPSJNMTEgNkgzIiAvPgogIDxwYXRoIGQ9Ik0xNSA2aDYiIC8+CiAgPHBhdGggZD0iTTE4IDlWMyIgLz4KICA8cGF0aCBkPSJNNyAxMmg4IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/list-filter-plus
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const ListFilterPlus = createLucideIcon('ListFilterPlus', __iconNode);

export default ListFilterPlus;
