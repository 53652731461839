import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  [
    'path',
    { d: 'M12 11.22C11 9.997 10 9 10 8a2 2 0 0 1 4 0c0 1-.998 2.002-2.01 3.22', key: '1rnhq3' },
  ],
  ['path', { d: 'm12 18 2.57-3.5', key: '116vt7' }],
  ['path', { d: 'M6.243 9.016a7 7 0 0 1 11.507-.009', key: '10dq0b' }],
  ['path', { d: 'M9.35 14.53 12 11.22', key: 'tdsyp2' }],
  [
    'path',
    {
      d: 'M9.35 14.53C7.728 12.246 6 10.221 6 7a6 5 0 0 1 12 0c-.005 3.22-1.778 5.235-3.43 7.5l3.557 4.527a1 1 0 0 1-.203 1.43l-1.894 1.36a1 1 0 0 1-1.384-.215L12 18l-2.679 3.593a1 1 0 0 1-1.39.213l-1.865-1.353a1 1 0 0 1-.203-1.422z',
      key: 'nmifey',
    },
  ],
];

/**
 * @component @name Ribbon
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMTEuMjJDMTEgOS45OTcgMTAgOSAxMCA4YTIgMiAwIDAgMSA0IDBjMCAxLS45OTggMi4wMDItMi4wMSAzLjIyIiAvPgogIDxwYXRoIGQ9Im0xMiAxOCAyLjU3LTMuNSIgLz4KICA8cGF0aCBkPSJNNi4yNDMgOS4wMTZhNyA3IDAgMCAxIDExLjUwNy0uMDA5IiAvPgogIDxwYXRoIGQ9Ik05LjM1IDE0LjUzIDEyIDExLjIyIiAvPgogIDxwYXRoIGQ9Ik05LjM1IDE0LjUzQzcuNzI4IDEyLjI0NiA2IDEwLjIyMSA2IDdhNiA1IDAgMCAxIDEyIDBjLS4wMDUgMy4yMi0xLjc3OCA1LjIzNS0zLjQzIDcuNWwzLjU1NyA0LjUyN2ExIDEgMCAwIDEtLjIwMyAxLjQzbC0xLjg5NCAxLjM2YTEgMSAwIDAgMS0xLjM4NC0uMjE1TDEyIDE4bC0yLjY3OSAzLjU5M2ExIDEgMCAwIDEtMS4zOS4yMTNsLTEuODY1LTEuMzUzYTEgMSAwIDAgMS0uMjAzLTEuNDIyeiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/ribbon
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Ribbon = createLucideIcon('Ribbon', __iconNode);

export default Ribbon;
