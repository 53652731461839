import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['path', { d: 'M12.338 21.994A10 10 0 1 1 21.925 13.227', key: '1i7shu' }],
  ['path', { d: 'M12 6v6l2 1', key: '19cm8n' }],
  ['path', { d: 'm14 18 4 4 4-4', key: '1waygx' }],
  ['path', { d: 'M18 14v8', key: 'irew45' }],
];

/**
 * @component @name ClockArrowDown
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIuMzM4IDIxLjk5NEExMCAxMCAwIDEgMSAyMS45MjUgMTMuMjI3IiAvPgogIDxwYXRoIGQ9Ik0xMiA2djZsMiAxIiAvPgogIDxwYXRoIGQ9Im0xNCAxOCA0IDQgNC00IiAvPgogIDxwYXRoIGQ9Ik0xOCAxNHY4IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/clock-arrow-down
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const ClockArrowDown = createLucideIcon('ClockArrowDown', __iconNode);

export default ClockArrowDown;
