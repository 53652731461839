import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['path', { d: 'M14 22v-4a2 2 0 1 0-4 0v4', key: 'hhkicm' }],
  [
    'path',
    {
      d: 'm18 10 3.447 1.724a1 1 0 0 1 .553.894V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-7.382a1 1 0 0 1 .553-.894L6 10',
      key: '1xqip1',
    },
  ],
  ['path', { d: 'M18 5v17', key: '1sw6gf' }],
  ['path', { d: 'm4 6 7.106-3.553a2 2 0 0 1 1.788 0L20 6', key: '9d2mlk' }],
  ['path', { d: 'M6 5v17', key: '1xfsm0' }],
  ['circle', { cx: '12', cy: '9', r: '2', key: '1092wv' }],
];

/**
 * @component @name School
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTQgMjJ2LTRhMiAyIDAgMSAwLTQgMHY0IiAvPgogIDxwYXRoIGQ9Im0xOCAxMCAzLjQ0NyAxLjcyNGExIDEgMCAwIDEgLjU1My44OTRWMjBhMiAyIDAgMCAxLTIgMkg0YTIgMiAwIDAgMS0yLTJ2LTcuMzgyYTEgMSAwIDAgMSAuNTUzLS44OTRMNiAxMCIgLz4KICA8cGF0aCBkPSJNMTggNXYxNyIgLz4KICA8cGF0aCBkPSJtNCA2IDcuMTA2LTMuNTUzYTIgMiAwIDAgMSAxLjc4OCAwTDIwIDYiIC8+CiAgPHBhdGggZD0iTTYgNXYxNyIgLz4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjkiIHI9IjIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/school
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const School = createLucideIcon('School', __iconNode);

export default School;
