import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['path', { d: 'M12 2a10 10 0 0 1 7.38 16.75', key: '175t95' }],
  ['path', { d: 'm16 12-4-4-4 4', key: '177agl' }],
  ['path', { d: 'M12 16V8', key: '1sbj14' }],
  ['path', { d: 'M2.5 8.875a10 10 0 0 0-.5 3', key: '1vce0s' }],
  ['path', { d: 'M2.83 16a10 10 0 0 0 2.43 3.4', key: 'o3fkw4' }],
  ['path', { d: 'M4.636 5.235a10 10 0 0 1 .891-.857', key: '1szpfk' }],
  ['path', { d: 'M8.644 21.42a10 10 0 0 0 7.631-.38', key: '9yhvd4' }],
];

/**
 * @component @name CircleFadingArrowUp
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMmExMCAxMCAwIDAgMSA3LjM4IDE2Ljc1IiAvPgogIDxwYXRoIGQ9Im0xNiAxMi00LTQtNCA0IiAvPgogIDxwYXRoIGQ9Ik0xMiAxNlY4IiAvPgogIDxwYXRoIGQ9Ik0yLjUgOC44NzVhMTAgMTAgMCAwIDAtLjUgMyIgLz4KICA8cGF0aCBkPSJNMi44MyAxNmExMCAxMCAwIDAgMCAyLjQzIDMuNCIgLz4KICA8cGF0aCBkPSJNNC42MzYgNS4yMzVhMTAgMTAgMCAwIDEgLjg5MS0uODU3IiAvPgogIDxwYXRoIGQ9Ik04LjY0NCAyMS40MmExMCAxMCAwIDAgMCA3LjYzMS0uMzgiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/circle-fading-arrow-up
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const CircleFadingArrowUp = createLucideIcon('CircleFadingArrowUp', __iconNode);

export default CircleFadingArrowUp;
