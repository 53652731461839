import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['path', { d: 'M12 17h1.5', key: '1gkc67' }],
  ['path', { d: 'M12 22h1.5', key: '1my7sn' }],
  ['path', { d: 'M12 2h1.5', key: '19tvb7' }],
  ['path', { d: 'M17.5 22H19a1 1 0 0 0 1-1', key: '10akbh' }],
  ['path', { d: 'M17.5 2H19a1 1 0 0 1 1 1v1.5', key: '1vrfjs' }],
  ['path', { d: 'M20 14v3h-2.5', key: '1naeju' }],
  ['path', { d: 'M20 8.5V10', key: '1ctpfu' }],
  ['path', { d: 'M4 10V8.5', key: '1o3zg5' }],
  ['path', { d: 'M4 19.5V14', key: 'ob81pf' }],
  ['path', { d: 'M4 4.5A2.5 2.5 0 0 1 6.5 2H8', key: 's8vcyb' }],
  ['path', { d: 'M8 22H6.5a1 1 0 0 1 0-5H8', key: '1cu73q' }],
];

/**
 * @component @name BookDashed
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMTdoMS41IiAvPgogIDxwYXRoIGQ9Ik0xMiAyMmgxLjUiIC8+CiAgPHBhdGggZD0iTTEyIDJoMS41IiAvPgogIDxwYXRoIGQ9Ik0xNy41IDIySDE5YTEgMSAwIDAgMCAxLTEiIC8+CiAgPHBhdGggZD0iTTE3LjUgMkgxOWExIDEgMCAwIDEgMSAxdjEuNSIgLz4KICA8cGF0aCBkPSJNMjAgMTR2M2gtMi41IiAvPgogIDxwYXRoIGQ9Ik0yMCA4LjVWMTAiIC8+CiAgPHBhdGggZD0iTTQgMTBWOC41IiAvPgogIDxwYXRoIGQ9Ik00IDE5LjVWMTQiIC8+CiAgPHBhdGggZD0iTTQgNC41QTIuNSAyLjUgMCAwIDEgNi41IDJIOCIgLz4KICA8cGF0aCBkPSJNOCAyMkg2LjVhMSAxIDAgMCAxIDAtNUg4IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/book-dashed
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const BookDashed = createLucideIcon('BookDashed', __iconNode);

export default BookDashed;
