import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['path', { d: 'M10 17H7l-4 4v-7', key: '1r71xu' }],
  ['path', { d: 'M14 17h1', key: 'nufu4t' }],
  ['path', { d: 'M14 3h1', key: '1ec4yj' }],
  ['path', { d: 'M19 3a2 2 0 0 1 2 2', key: '18rm91' }],
  ['path', { d: 'M21 14v1a2 2 0 0 1-2 2', key: '29akq3' }],
  ['path', { d: 'M21 9v1', key: 'mxsmne' }],
  ['path', { d: 'M3 9v1', key: '1r0deq' }],
  ['path', { d: 'M5 3a2 2 0 0 0-2 2', key: 'y57alp' }],
  ['path', { d: 'M9 3h1', key: '1yesri' }],
];

/**
 * @component @name MessageSquareDashed
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgMTdIN2wtNCA0di03IiAvPgogIDxwYXRoIGQ9Ik0xNCAxN2gxIiAvPgogIDxwYXRoIGQ9Ik0xNCAzaDEiIC8+CiAgPHBhdGggZD0iTTE5IDNhMiAyIDAgMCAxIDIgMiIgLz4KICA8cGF0aCBkPSJNMjEgMTR2MWEyIDIgMCAwIDEtMiAyIiAvPgogIDxwYXRoIGQ9Ik0yMSA5djEiIC8+CiAgPHBhdGggZD0iTTMgOXYxIiAvPgogIDxwYXRoIGQ9Ik01IDNhMiAyIDAgMCAwLTIgMiIgLz4KICA8cGF0aCBkPSJNOSAzaDEiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/message-square-dashed
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const MessageSquareDashed = createLucideIcon('MessageSquareDashed', __iconNode);

export default MessageSquareDashed;
