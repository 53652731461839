import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['path', { d: 'M21 12H11', key: 'wd7e0v' }],
  ['path', { d: 'M21 18H11', key: '4wu86t' }],
  ['path', { d: 'M21 6H11', key: '6dy1d6' }],
  ['path', { d: 'm7 8-4 4 4 4', key: 'o5hrat' }],
];

/**
 * @component @name IndentDecrease
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjEgMTJIMTEiIC8+CiAgPHBhdGggZD0iTTIxIDE4SDExIiAvPgogIDxwYXRoIGQ9Ik0yMSA2SDExIiAvPgogIDxwYXRoIGQ9Im03IDgtNCA0IDQgNCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/indent-decrease
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const IndentDecrease = createLucideIcon('IndentDecrease', __iconNode);

export default IndentDecrease;
