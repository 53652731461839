import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['circle', { cx: '12', cy: '12', r: '4', key: '4exip2' }],
  ['path', { d: 'M12 4h.01', key: '1ujb9j' }],
  ['path', { d: 'M20 12h.01', key: '1ykeid' }],
  ['path', { d: 'M12 20h.01', key: 'zekei9' }],
  ['path', { d: 'M4 12h.01', key: '158zrr' }],
  ['path', { d: 'M17.657 6.343h.01', key: '31pqzk' }],
  ['path', { d: 'M17.657 17.657h.01', key: 'jehnf4' }],
  ['path', { d: 'M6.343 17.657h.01', key: 'gdk6ow' }],
  ['path', { d: 'M6.343 6.343h.01', key: '1uurf0' }],
];

/**
 * @component @name SunDim
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjEyIiByPSI0IiAvPgogIDxwYXRoIGQ9Ik0xMiA0aC4wMSIgLz4KICA8cGF0aCBkPSJNMjAgMTJoLjAxIiAvPgogIDxwYXRoIGQ9Ik0xMiAyMGguMDEiIC8+CiAgPHBhdGggZD0iTTQgMTJoLjAxIiAvPgogIDxwYXRoIGQ9Ik0xNy42NTcgNi4zNDNoLjAxIiAvPgogIDxwYXRoIGQ9Ik0xNy42NTcgMTcuNjU3aC4wMSIgLz4KICA8cGF0aCBkPSJNNi4zNDMgMTcuNjU3aC4wMSIgLz4KICA8cGF0aCBkPSJNNi4zNDMgNi4zNDNoLjAxIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/sun-dim
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const SunDim = createLucideIcon('SunDim', __iconNode);

export default SunDim;
